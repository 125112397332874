<template>
  <div class="flex justify-center">
    <!-- loading view -->
    <div v-if="isLoading" class="flex items-center justify-center" :style="`height: ${innerHeight}px`">
      <Icon type="circle-notch" class="text-3xl" spinner />
    </div>

    <!-- error view -->
    <div v-if="errorStatus" class="flex flex-col justify-center w-full" :style="`height: ${innerHeight}px`">
      <div class="text-center text-gray-300 mb-8">
        <span class="fa-stack fa-3x">
          <i class="fal fa-calendar fa-stack-2x"></i>
          <i class="fas fa-question fa-stack-1x translate-y-[0.9rem]"></i>
        </span>
      </div>

      <div class="text-3xl text-center font-bold mb-4">No appointment found</div>
      <div class="mb-10 text-center px-2">Please get in touch</div>
    </div>

    <!-- main view -->
    <div v-if="!errorStatus && !isLoading" class="flex flex-col justify-between w-full" :style="`height: ${innerHeight}px`">
      <div class="w-full flex flex-col items-center p-4">
        <div class="mb-8 mt-8 flex justify-center">
          <img class="w-full max-w-[60px] max-h-[40px]" :src="state.manufacturerLogoUrl" alt="Logo" />
        </div>

        <div class="flex justify-center">
          <div
            class="sm:text-md mb-6 px-3 py-1 pb-[6px] leading-none rounded-xl bg-gray-100 text-gray-700"
            :style="appStatus.status == 'current' ? { color: state.manufacturerColor } : ''"
          >
            {{ appStatus.title }}
          </div>
        </div>

        <div class="border border-gray-200 rounded-lg sm:max-w-[430px] w-full px-6 py-4 mb-2">
          <div
            class="text-3xl sm:text-2xl font-bold text-center sm:text-left"
            :class="{
              'line-through text-danger font-normal': state.isCancelled
            }"
          >
            {{ getDateTimeString(state.time) }}
          </div>

          <div
            v-if="!state.isCancelled && state.customerTimeZone != state.timeZone"
            class="mb-2 mt-1 text-gray-500 text-center sm:text-left text-xl sm:text-md"
          >
            ({{ getTimeZoneString }} time)
          </div>

          <div class="w-full">
            <div class="w-full h-[1px] bg-gray-200 mt-4 mb-4"></div>
            <AppointmentMethod v-model="state" :app-status="appStatus" />
          </div>
        </div>

        <div v-if="appStatus.status === 'future'" class="flex flex-col gap-3 sm:flex-row sm:max-w-[430px] m-2 w-full">
          <Button
            label="Reschedule"
            severity="secondary"
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            outlined
            class="w-full"
            @click="
              (isRescheduleDateModalOpen = true), (availability = fetchAppAvailability(state.dealershipID, dateRangeStart, dateRangeEnd))
            "
          />
          <Button
            label="Cancel"
            severity="danger"
            outlined
            class="w-full"
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            @click="isCancelModalOpen = true"
          />
        </div>
      </div>

      <div class="flex justify-center">
        <!-- <Button
          plain
          label="Contact Us"
          severity="secondary"
          text
          :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
          class="sm:max-w-[10rem] w-full mb-6"
          @click="isContactModalOpen = true"
        /> -->
      </div>
    </div>

    <!-- reschedule modal -->
    <div v-if="isRescheduleDateModalOpen == true" class="modal-wrapper" :style="`height: ${innerHeight}px`">
      <div v-click-away="closeModal" class="bg-white rounded-md relative w-full h-[100%] sm:h-auto sm:w-auto sm:p-10">
        <div class="cursor-pointer absolute top-3 right-3 p-2">
          <Icon fa-style="fas" type="xmark" class="text-3xl sm:text-md" @click="isRescheduleDateModalOpen = false" />
        </div>

        <b>Oops!</b><br />We are currently bringing new features to the rescheduling calendar!<br />Please contact us to reschedule your
        appointment.<br /><br />If you wish to cancel instead, please click the button below.<br /><br />
        <div class="w-full">
          <Button
            label="Cancel Appointment"
            severity="danger"
            outlined
            class="w-full"
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            @click="(isCancelModalOpen = true), (isRescheduleDateModalOpen = false)"
          />
        </div>

        <!-- <div>
          <div class="mt-20 sm:mt-0">
            <div class="mb-10 sm:mb-6 text-3xl sm:text-2xl font-bold text-center sm:text-left">Pick a date</div>
          </div>

          <div class="flex justify-center mb-6 w-full">
            <AppointmentPicker
              v-model="selectedDateTime"
              v-model:available-dates="availableDates"
              :display-style="useBreakPoints({ xs: 'paged', sm: 'inline' })"
              :compact="false"
              :traffic-light="false"
              @date-range-updated="onDateRangeUpdated"
              @on-confirm="findAvailableMethods(), (isRescheduleMethodModalOpen = true), (isRescheduleDateModalOpen = false)"
            />
          </div>
        </div> -->
      </div>
    </div>

    <!-- reschedule method picker modal -->
    <div v-if="isRescheduleMethodModalOpen == true" class="modal-wrapper" :style="`height: ${innerHeight}px`">
      <div
        v-click-away="closeModal"
        class="bg-white rounded-md relative w-full h-[100%] sm:h-auto sm:w-auto sm:p-10 flex flex-col justify-between"
      >
        <div>
          <div class="cursor-pointer absolute top-3 right-3 p-2">
            <Icon
              fa-style="fas"
              type="xmark"
              class="text-3xl sm:text-md leading-snug"
              @click="(isRescheduleMethodModalOpen = false), (selectedMethod = null)"
            />
          </div>

          <div
            class="cursor-pointer p-0 flex items-center absolute top-3 left-3 sm:top-5 sm:left-5"
            @click="(isRescheduleDateModalOpen = true), (isRescheduleMethodModalOpen = false), (selectedMethod = null)"
          >
            <Icon fa-style="fas" type="chevron-left" class="text-3xl sm:text-md text-gray-500" />
            <div class="text-3xl sm:text-md text-gray-500 ml-1 p-0 leading-snug">{{ getDateTimeString(selectedDateTime) }}</div>
          </div>

          <div class="mt-20 sm:mt-5">
            <div class="sm:mb-6 text-3xl sm:text-2xl font-bold text-center sm:text-left leading-none">Pick a method</div>
          </div>

          <div class="m-6 sm:m-0 mt-10 sm:mt-6 sm:mb-7">
            <RadioBoxed v-model="selectedMethod" :options="methodState" />
          </div>
        </div>
        <div class="w-full flex justify-center mb-6 sm:mb-0">
          <Button
            label="Confirm"
            :disabled="!selectedMethod"
            severity="primary"
            :is-loading="isRescheduleLoading"
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            :background-color="state.manufacturerColor"
            class="w-full m-2 sm:m-0"
            @click.stop="rescheduleAppointment(state.id, selectedDateTime)"
          />
        </div>
      </div>
    </div>

    <!-- reschedule confirmation modal -->
    <div v-if="isRescheduleConfirmationModalOpen" class="modal-wrapper justify-center" :style="`height: ${innerHeight}px`">
      <div
        v-click-away="closeModal"
        class="bg-white rounded-md relative h-[100%] w-full sm:h-auto sm:max-w-96 sm:p-10 flex flex-col justify-between"
      >
        <div>
          <div class="absolute cursor-pointer top-3 right-3 p-2">
            <Icon fa-style="fas" type="xmark" class="text-3xl sm:text-md" @click="isRescheduleConfirmationModalOpen = false" />
          </div>
        </div>

        <div>
          <div class="text-center mt-20 sm:mt-4">
            <Icon
              fa-style="fad"
              type="calendar-check"
              class="text-9xl mb-6"
              :style="{
                '--fa-primary-color': state.manufacturerColor,
                '--fa-secondary-color': 'lightgray'
              }"
            />
          </div>
          <div class="mb-2 text-xl sm:text-md text-center line-through text-gray-500">
            {{ getDateTimeString(stateInit.time) }}
          </div>
          <div class="mb-8 text-3xl sm:text-2xl text-center">{{ getDateTimeString(state.time) }}</div>
        </div>
        <div>
          <div class="p-2 sm:p-0 mb-6 sm:mb-0">
            <!-- TODO: implement add to calendar functionality -->
            <!-- <Button
              id="'atc-button'"
              label="Add to Calendar"
              severity="primary"
              class="w-full mb-2"
              :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
              :style="{ backgroundColor: state.manufacturerColor }"
              @click="addToCalendar()"
            /> -->
            <Button
              label="View Appointment"
              severity="secondary"
              outlined
              class="w-full"
              :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
              :style="{ color: 'black', 'border-color': 'black' }"
              @click="isRescheduleConfirmationModalOpen = false"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- cancel appointment modal -->
    <div v-if="isCancelModalOpen" class="modal-wrapper" :style="`height: ${innerHeight}px`">
      <div
        v-click-away="closeModal"
        class="bg-white flex flex-col justify-between rounded-md relative h-[100%] w-full sm:h-auto sm:w-auto sm:p-10"
      >
        <div class="absolute cursor-pointer top-3 right-3 p-2">
          <Icon fa-style="fas" type="xmark" class="text-3xl sm:text-md" @click="isCancelModalOpen = false" />
        </div>

        <div>
          <div class="mt-20 sm:mt-0 text-center text-3xl sm:text-2xl sm:text-left font-bold">Cancel Appointment</div>
          <div class="text-2xl text-center sm:text-left text-xl sm:text-md mb-6 sm:mb-2 mt-6 sm:mt-4">Why do you wish to cancel?</div>
          <div class="flex flex-col items-center mb-6">
            <Textarea
              v-model="cancelAppReason"
              placeholder="I have other commitments..."
              rows="3"
              auto-resize
              spell-check
              class="px-2 sm:p-0 sm:min-w-96 w-full"
            />
          </div>
        </div>

        <div class="flex flex-col flex-end sm:flex-row gap-2 items-center p-2 sm:p-0 mb-6 sm:mb-0">
          <Button
            label="Reschedule"
            severity="secondary"
            outlined
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            class="flex items-center justify-center sm:max-w-96 w-full"
            @click="(isCancelModalOpen = false), (isRescheduleDateModalOpen = true)"
          />
          <Button
            label="Cancel Appointment"
            severity="danger"
            outlined="true"
            :is-loading="isCancelLoading"
            :size="useBreakPoints({ xs: 'xl', sm: 'md' })"
            class="sm:max-w-96 w-full"
            @click.stop="cancelAppointment(state.id)"
          />
          <!--  -->
        </div>
      </div>
    </div>

    <!-- contact us modal  -->
    <div v-if="isContactModalOpen" class="modal-wrapper" :style="`height: ${innerHeight}px`">
      <div v-click-away="closeModal" class="bg-white rounded-md relative h-[100%] w-full sm:h-auto sm:max-w-96 sm:p-10">
        <div class="absolute cursor-pointer top-3 right-3 p-2">
          <Icon fa-style="fas" type="xmark" class="text-3xl sm:text-md" @click="isContactModalOpen = false" />
        </div>

        <div class="flex flex-col">
          <div class="mt-20 sm:mt-0 text-3xl sm:text-2xl font-bold text-center sm:text-left mb-10 sm:mb-6">Contact Us</div>

          <div class="flex items-center justify-center sm:justify-normal mb-6 sm:mb-4">
            <Icon type="phone" fa-style="far" class="mr-1 text-xl sm:text-lg" :style="{ color: state.manufacturerColor }" />
            <div :style="{ color: state.manufacturerColor }" class="text-center">
              <a href="tel:+441183247000" class="text-xl sm:text-lg">+44(0)1183247000</a>
            </div>
          </div>
          <!-- // TODO: fetch this from the API endpoint-->
          <!--  <div class="text-gray-500 text-center sm:text-left">Mon-Fri: 9am - 5pm</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import axios from 'axios'
import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import AppointmentMethod from '@/components/AppointmentMethod.vue'
import AppointmentPicker from '@/components/AppointmentPicker.vue'
import Textarea from '@/components/forms/TextArea.vue'
import RadioBoxed from '@/components/forms/RadioBoxed.vue'

import { useBreakPoints } from '@/composables/useWindowSize.js'

import dayjs from 'dayjs'
import { atcb_action } from 'add-to-calendar-button'

export default {
  components: {
    Icon,
    Button,
    AppointmentMethod,
    AppointmentPicker,
    Textarea,
    RadioBoxed
  },
  setup() {
    const stateDefault = {
      id: 1,
      time: null,
      method: null,
      dealershipID: null,
      dealershipName: null,
      dealershipAddress: null,
      manufacturerColor: null,
      manufacturerLogoUrl: null,
      isCancelled: null,
      status: null,
      timeZone: null
    }

    const stateInit = ref({ ...stateDefault })
    const state = ref({ ...stateDefault })
    const errorStatus = ref(false)
    const isLoading = ref(false)

    const isRescheduleLoading = ref(false)
    const isRescheduleDateModalOpen = ref(false)
    const isRescheduleMethodModalOpen = ref(false)
    const isRescheduleConfirmationModalOpen = ref(false)
    const isContactModalOpen = ref(false)
    const isCancelModalOpen = ref(false)
    const isCancelLoading = ref(false)

    const innerHeight = ref(window.innerHeight)
    const cancelAppReason = ref('')

    const rescheduleView = ref('dateTime')
    const selectedDateTime = ref(null)
    const availableDates = ref()
    const selectedMethod = ref(null)
    const dateRangeStart = ref(dayjs().startOf('month').format('YYYY-MM-DD'))
    const dateRangeEnd = ref(dayjs().endOf('month').format('YYYY-MM-DD'))

    const methodState = ref([
      {
        value: 'physical',
        label: 'Dealership',
        icon: 'location-dot',
        disabled: false,
        classes: 'min-w-[100px] text-center'
      },
      {
        value: 'video_external',
        label: 'Video',
        icon: 'video',
        disabled: false,
        classes: 'min-w-[100px] text-center'
      },
      {
        value: 'phone',
        label: 'Phone',
        icon: 'phone',
        disabled: false,
        classes: 'min-w-[100px] text-center'
      }
    ])

    const appStatus = ref({
      status: null, // past, future, current
      title: null // 'Past Appointment', 'Upcoming Appointment', 'Active Appointment'
    })

    const statuses = [
      {
        status: 'past',
        title: 'Past Appointment'
      },
      {
        status: 'current',
        title: 'Current Appointment'
      },
      {
        status: 'future',
        title: 'Upcoming Appointment'
      },
      {
        status: 'cancelled',
        title: 'Cancelled Appointment'
      }
    ]

    async function fetchAppointmentData(id) {
      return new Promise((resolve, reject) => {
        if (typeof id === 'number' && id > 0) {
          try {
            axios
              .get(`https://api.retainauto.com/v1/appointments/${id}`, {
                headers: {
                  'X-Api-Access-Token':
                    'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
                }
              })
              .then(response => {
                if (response.data.response.status == 'success') {
                  let data = response.data.data
                  if (data.id && data.method && data.time) {
                    state.value = data
                    updateStatus()
                    resolve()
                  } else {
                    console.error(`Some data was missing for appointment with id: ${id}`)
                    reject()
                  }
                } else {
                  errorStatus.value = true
                  console.error(response.data.response.message)
                  reject()
                }
                isLoading.value = false
              })
          } catch (error) {
            isLoading.value = false
            console.error(`The request failed: ${error.message}`)
            reject()
          }
        } else {
          isLoading.value = false
          console.error("Couldn't load content - invalid or missing appointment ID")
          reject()
        }
      })
    }

    function fetchAppAvailability(id, startDate, endDate) {
      if (typeof id === 'number' && id > 0 && typeof startDate === 'string' && startDate && typeof endDate === 'string' && endDate) {
        try {
          axios
            .get(`https://api.retainauto.com/v1/availability`, {
              headers: {
                'X-Api-Access-Token':
                  'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
              },
              params: {
                dealershipId: id,
                startDate: startDate,
                endDate: endDate
              }
            })
            .then(response => {
              if (response.data.response.status == 'success') {
                let responseData = response.data.data
                availableDates.value = responseData
              } else {
                console.error(response)
              }
            })
        } catch (error) {
          console.error(`The request failed: ${error.message}`)
        }
      } else {
        console.error('Invalid params')
      }
    }

    function cancelAppointment(id) {
      isCancelLoading.value = true
      if (id && typeof id === 'number') {
        try {
          axios
            .patch(
              `https://api.retainauto.com/v1/appointments/${id}/cancel`,
              {
                notifyCustomer: true,
                updateSource: 'customer',
                updatedByUserID: null,
                updateNote: cancelAppReason.value
              },
              {
                headers: {
                  'X-Api-Access-Token':
                    'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
                }
              }
            )
            .then(response => {
              if (response.data.response.status == 'success') {
                fetchAppointmentData(state.value.id).then(() => {
                  isCancelLoading.value = false
                  isCancelModalOpen.value = false
                })
              } else {
                console.error('failed to cancel appointment')
                isCancelLoading.value = false
                isCancelModalOpen.value = false
              }
            })
        } catch (error) {
          console.error(`The request failed: ${error.message}`)
        }
      } else {
        console.error('invalid or missing appointment id')
      }
    }

    function rescheduleAppointment(id, dateTime) {
      isRescheduleLoading.value = true

      if (id && typeof id === 'number' && dateTime && typeof dateTime === 'string') {
        // initialise stateInit with old date before updating
        stateInit.value = state.value
        try {
          axios
            .patch(
              `https://api.retainauto.com/v1/appointments/${id}/reschedule`,
              {
                method: selectedMethod.value,
                time: dateTime,
                timeZone: state.value.timeZone,
                timeUtc: state.value.timeUtc,
                notifyCustomer: true,
                notifyDealership: true,
                updateSource: 'customer',
                updatedByUserID: null,
                note: state.value.note
              },
              {
                headers: {
                  'X-Api-Access-Token':
                    'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
                }
              }
            )
            .then(response => {
              if (response.data.success) {
                fetchAppointmentData(state.value.id).then(() => {
                  isRescheduleLoading.value = false
                  isRescheduleMethodModalOpen.value = false
                  isRescheduleConfirmationModalOpen.value = true
                })
              } else {
                console.error(`failed to reschedule appointment with id: ${id}`)
              }
            })
        } catch (error) {
          console.error(`The request failed: ${error.message}`)
        }
      } else {
        console.error('invalid or missing appointment id')
      }
    }

    function getDateTimeString(dateTime) {
      let timeString

      // if 0 mins format without mins - e.g. 3:00pm => 3pm
      if (dayjs(dateTime).format('mm') === '00') {
        timeString = dayjs(dateTime).format('ha')
      } else {
        timeString = dayjs(dateTime).format('h:mma')
      }

      return dayjs(dateTime)
        .calendar(null, {
          sameDay: '[Today at] ', // The same day ( Today at 2:30 AM )
          nextDay: '[Tomorrow at] ', // The next day ( Tomorrow at 2:30 AM )
          nextWeek: 'dddd [at] ', // The next week ( Sunday at 2:30 AM )
          lastDay: '[Yesterday] [at] ', // The day before ( Yesterday at 2:30 AM )
          lastWeek: '[Last] dddd [at] ', // Last week ( Last Monday at 2:30 AM )
          sameElse: 'Do MMMM YYYY [at] ' // Everything else ( 1st March 2024 at 10:30am )
        })
        .concat(timeString)
    }

    const handleResize = () => {
      innerHeight.value = window.innerHeight
    }

    const getTimeZoneString = computed(() => {
      let string = state.value.timeZone
      if (string.includes('_')) {
        string = string.split('_').join(' ')
      }
      return string
    })

    function onDateRangeUpdated(payload) {
      dateRangeStart.value = payload.start
      dateRangeEnd.value = payload.end
      fetchAppAvailability(state.value.dealershipID, dateRangeStart.value, dateRangeEnd.value)
    }

    function findAvailableMethods() {
      let selectedDateData = availableDates.value.find(d => d.date === dayjs(selectedDateTime.value).format('YYYY-MM-DD'))
      let availableMethods = selectedDateData?.availability?.appointmentMethods ?? []

      methodState.value.forEach(m => {
        if (availableMethods.find(val => val == m.value)) {
          m.disabled = false
        } else {
          m.disabled = true
        }
      })
    }

    function closeModal() {
      isRescheduleDateModalOpen.value = false
      isRescheduleMethodModalOpen.value = false
      isRescheduleConfirmationModalOpen.value = false
      isContactModalOpen.value = false
      isCancelModalOpen.value = false
    }

    function setStatus(status) {
      appStatus.value = statuses.find(s => s.status == status)
    }

    function updateFavicon(faviconPath) {
      const link = document.querySelector("link[rel='icon']") || document.createElement('link')
      link.type = 'image/svg+xml'
      link.rel = 'icon'
      link.href = faviconPath
      document.head.appendChild(link)
    }

    // const addToCalendarConfig = ref({
    //   name: 'Appointment: ' + state.value.dealershipName,
    //   description: '',
    //   startDate: dayjs(state.value.time).format('YYYY-MM-DD'),
    //   endDate: dayjs(state.value.time).format('YYYY-MM-DD'),
    //   startTime: dayjs(state.value.time).format('hh:mm'),
    //   endTime: dayjs(state.value.time).add(60, 'minutes').format('hh:mm'),
    //   timeZone: state.value.timeZone,
    //   options: ['Google', 'Apple', 'Microsoft365', 'Outlook.com']
    // })

    // function addToCalendar() {

    //   let button = document.getElementById('atc-button')
    //   atcb_action(addToCalendarConfig.value, button)
    // }

    function updateStatus() {
      const appLength = 60
      let appTime = dayjs(state.value.time)
      let newStatus

      if (state.value.isCancelled) {
        newStatus = 'cancelled'
      } else if (dayjs() > appTime.add(60, 'minutes')) {
        newStatus = 'past'
      } else if (dayjs() >= appTime && dayjs() <= appTime.add(appLength, 'minutes')) {
        newStatus = 'current'
      } else {
        newStatus = 'future'
      }
      if (newStatus != appStatus.value.status) {
        setStatus(newStatus)
      }
    }

    setInterval(updateStatus, 1000)

    onMounted(() => {
      const route = useRoute()
      const appointmentId = route.params.aid

      if (!appointmentId || !Number(atob(appointmentId))) {
        errorStatus.value = true
      } else {
        isLoading.value = true
        fetchAppointmentData(Number(atob(appointmentId))).then(() => {
          if (state.value.manufacturerLogoUrl) {
            updateFavicon(state.value.manufacturerLogoUrl)
          }
        })
      }

      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      innerHeight,
      stateInit,
      state,
      isRescheduleDateModalOpen,
      isRescheduleMethodModalOpen,
      isRescheduleLoading,
      rescheduleView,
      errorStatus,
      isLoading,
      isRescheduleConfirmationModalOpen,
      isContactModalOpen,
      isCancelModalOpen,
      isCancelLoading,
      cancelAppReason,
      appStatus,
      getDateTimeString,
      getTimeZoneString,
      fetchAppAvailability,
      onDateRangeUpdated,
      cancelAppointment,
      rescheduleAppointment,
      selectedDateTime,
      availableDates,
      methodState,
      selectedMethod,
      findAvailableMethods,
      dateRangeStart,
      dateRangeEnd,
      closeModal,
      useBreakPoints
      // addToCalendarConfig,
      // addToCalendar
    }
  }
}
</script>

<style>
.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}
add-to-calendar-button#atcb::part(atcb-button) {
  border: 1px solid black;
  background-color: #fff;
  font-weight: 550;
  font-size: var(--text-md);
}
add-to-calendar-button#atcb::part(atcb-button-wrapper) {
  width: 100%;
}
/* add-to-calendar-button#atcb::part(atcb-btn-1) {
  background-color: #264f3c;
  color: #fff;
  min-width: none !important;
  width: full !important;
} */
</style>
